import React from 'react';
import {Link} from 'gatsby'

const FeaturedServices = () => {
    return (
        <section className="featured-services-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>How We Take Your Business From Good To Great</h2>
                    <p>Our passion drives us! Our passionate people focus on developing tailored edge solutions, with data-driven insight being a key standard outcome.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-featured-services-box">
                            <div className="icon">
                                <i className="flaticon-analysis"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Collect and Analyse
                                </Link>
                            </h3>
                            <p>Connect to IoT sensors and stream data in real-time.</p>
                            <ul className="features-list">
                                <li>Real-time data streaming</li>
                                <li>Streaming analytics</li>
                                <li>Elastic data store and sync</li>
                            </ul>
                            {/* <Link to="/service-details" className="read-more-btn">
                                <i className="flaticon-right"></i> Read More
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-featured-services-box">
                            <div className="icon">
                                <i className="flaticon-structure"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Develop A Customised Plan
                                </Link>
                            </h3>
                            <p>Tailored software provisioning and scaling solutions for your Egde Network.</p>
                            <ul className="features-list">
                                <li>Tailored end-to-end DevOps Toolchain</li>
                                <li>Tailored Web and Mobile platforms</li>
                                <li>Custom dashboards</li>
                            </ul>
                            {/* <Link to="/service-details" className="read-more-btn">
                                <i className="flaticon-right"></i> Read More
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="single-featured-services-box">
                            <div className="icon">
                                <i className="flaticon-idea"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Implement Your Solution
                                </Link>
                            </h3>
                            <p>Our team of experts play a key-role in delivering our edge solutions.</p>
                            <ul className="features-list">
                                <li>Environment provisioning and scaling</li>
                                <li>24/7 Monitoring and Logging</li>
                                <li>Support and product enhancement</li>
                            </ul>
                            
                            {/* <Link to="/service-details" className="read-more-btn">
                                <i className="flaticon-right"></i> Read More
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturedServices;